import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components/macro"

import { encode, pxToRem, validate } from "../utils"

const Wrapper = styled.div`
  max-width: 275px;
  border: ${props =>
    props.color ? `1px solid ${props.color}}` : "1px solid #fff"};
  /* position: relative; */
  position: fixed;
  z-index: 99999;
  top: 15vh;
  left: ${props => (props.isOpen ? "0" : "-275px")};
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) -90%,
    rgba(0, 0, 0, 1) 30%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=1);
  transition: left 0.3s ease-in-out;

  @media all and (min-width: 1024px) {
    max-width: 377px;
    left: ${props => (props.isOpen ? "0" : "-377px")};
  }
`
const TitleBox = styled.div`
  display: none;
  font-weight: 700;
  font-style: italic;
  font-size: ${pxToRem(22)};
  font-family: "Montserrat", sans-serif;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  padding: ${pxToRem(0)} ${pxToRem(20)};
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  color: #000;
  background-color: ${props => (props.color ? `${props.color}` : "#fff")};
  transform: rotate(90deg);
  width: 270px;
  height: 35px;
  text-align: center;
  position: absolute;
  right: -153.5px;
  top: 116.5px;

  @media all and (min-width: 1200px) {
    display: block;
  }
`
const Container = styled.div`
  padding: ${pxToRem(20)};
  overflow-x: auto;
  max-height: 85vh;
`
const Title = styled.h2`
  font-weight: 300;
  font-style: italic;
  font-size: ${pxToRem(40)};
  margin-top: 0;
  margin-bottom: ${pxToRem(5)};
`
const Description = styled.p``
const Strong = styled.span`
  font-weight: 700;
`
const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`
const TextInput = styled.input`
  width: 100%;
  color: #fff;
  border: 1px solid #fff;
  background-color: #1a1a1a;
  font-size: 1rem;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  padding: 0.5rem 1rem;
  margin-bottom: 0;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 300;
  }

  &:focus {
    outline: none;
  }
`
const TextArea = styled.textarea`
  display: block;
  min-width: 100%;
  min-height: 250px;
  max-width: 1300px;
  width: 100%;
  color: #fff;
  border: none;
  border: 1px solid #fff;
  background-color: #1a1a1a;
  font-size: 1rem;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  padding: 0.5rem 1rem;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  &:focus {
    outline: none;
  }
`
const Label = styled.label`
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  color: #fff;
`
const Checkbox = styled.input`
  width: 1rem;
  height: 1rem;
  margin: 0;
  margin-right: 1rem;
`
const Button = styled.button`
  align-self: center;
  justify-self: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.5em;
  line-height: 1;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #000;
  padding: 12px 20px;
  border-radius: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  max-width: 200px;
  height: 50px;
  background-color: ${props => (props.color ? `${props.color}` : "#fff")};
  transition: color 0.3s ease-in-out;
  margin-bottom: calc(env(safe-area-inset-bottom) + 10px);

  &:disabled,
  &[disabled] {
    cursor: not-allowed;

    &:hover {
      color: #000;
    }
  }

  &:hover {
    color: #fff;
  }
`
const Error = styled.span`
  display: inline-block;
  margin-top: 5px;
  opacity: 0.6;
  font-weight: 300;
`
const StyledLink = styled(Link)`
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  display: inline-block;
  margin: 0 5px;
`
const IconBox = styled.a`
  display: block;
  font-weight: 700;
  font-style: italic;
  font-size: ${pxToRem(22)};
  font-family: "Montserrat", sans-serif;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  padding: ${pxToRem(8)} ${pxToRem(0)} ${pxToRem(0)} ${pxToRem(0)};
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  color: #000;
  background-color: ${props => (props.color ? `${props.color}` : "#fff")};
  transform: rotate(90deg);
  height: 41px;
  width: 41px;
  text-align: center;
  position: absolute;
  right: -42px;
  top: -1px;

  @media all and (min-width: 1200px) {
    display: none;
  }
`

const SlideInForm = ({
  titleBox,
  title,
  description,
  color,
  strongPart,
  mailchimpTags,
  webhookURL,
  isOpen,
  toggleIsOpen,
  formName,
}) => {
  const [state, setState] = useState({})
  const [error, setError] = useState({})

  const handleChange = e => {
    const newState = { ...state, [e.target.name]: e.target.value }
    setState(newState)
    validate(state, setError).catch(() => {})
  }

  const handleTerms = async () => {
    const newState = { ...state, terms: !state.terms }
    setState(newState)
    validate(newState, setError).catch(() => {})
  }

  const handleMarketing = async () => {
    const newState = { ...state, marketing: !state.marketing }
    setState(newState)
    validate(newState, setError).catch(() => {})
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const form = e.target
    // try {
    //   await validate(state, setError)
    //   const response = await axios({
    //     method: "post",
    //     url: webhookURL,
    //     headers: { Authorization: `Bearer ${process.env.API_SIGNATURE_TOKEN}` },
    //     data: {
    //       email_address: state.email,
    //       status: "subscribed",
    //       merge_fields: {
    //         FNAME: state.firstName,
    //         LNAME: state.lastName,
    //         ADATKEZEL: new Date(Date.now()).toLocaleDateString("en"),
    //         ASZF: new Date(Date.now()).toLocaleDateString("en"),
    //         ADATELF: state.terms === "on" ? "Igen" : "Nem",
    //         ASZFELF: state.terms === "on" ? "Igen" : "Nem",
    //         ADATCIM: "https://flipunitprod.com/terms-and-conditions",
    //         ASZFCIM: "https://flipunitprod.com/privacy-policy",
    //         UZENET: state.description,
    //         VIDEO: state.video,
    //       },
    //       tags: [
    //         ...mailchimpTags,
    //         ...(state.marketing === "on"
    //           ? ["Hozzájárult a hírlevelekhez"]
    //           : []),
    //       ],
    //     },
    //   })
    // } catch (error) {
    //   console.log(error)
    // }
    validate(state, setError)
      .then(() => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": form.getAttribute("name"),
            ...state,
          }),
        })
          .then(() => navigate(form.getAttribute("action")))
          .catch(error => alert(error))
      })
      .catch(error => {})
  }
  return (
    <Wrapper color={color} isOpen={isOpen}>
      <TitleBox color={color} onClick={toggleIsOpen}>
        {titleBox}
      </TitleBox>
      <IconBox color={color} href="#contact">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          width="31.25"
          height="25"
          viewBox="0 0 155 85"
          style={{
            transform: "rotate(-90deg)",
          }}
        >
          <path
            d="M60,0 l50,0 a10,10 0 0,1 7,17 l-50,50 a10,10 0 0,1 -13,0 l-50,-50 a10,10 0 0,1 7,-17z"
            stroke="#000"
            stroke-width="0"
            fill="#000"
          />
          <path
            d="M60,90 l54,0 a10,10 0 0,0 7,-7 l0,-60 -50,50 a15,15 0 0,1 -21,0 l-50,-50 0,60 a10,10 0 0,0 7,7z"
            stroke="#000"
            stroke-width="0"
            fill="#000"
          />
        </svg>
      </IconBox>
      <Container>
        <Title>{title}</Title>
        <Description>
          {description} <Strong>{strongPart}</Strong>
        </Description>
        <Form
          name={formName}
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          className="wow fadeIn"
          action="/thank-you"
        >
          <TextInput
            type="hidden"
            name="form-name"
            value={formName}
            onChange={handleChange}
          />
          <div>
            <TextInput
              type="text"
              name="firstName"
              placeholder="First name *"
              onChange={handleChange}
            />
            {error.firstName && <Error>{error.firstName}</Error>}
          </div>
          <div>
            <TextInput
              type="text"
              name="lastName"
              placeholder="Last name *"
              onChange={handleChange}
            />
            {error.lastName && <Error>{error.lastName}</Error>}
          </div>
          <div>
            <TextInput
              type="text"
              name="company"
              placeholder="Company *"
              onChange={handleChange}
            />
            {error.company && <Error>{error.company}</Error>}
          </div>
          <div>
            <TextInput
              type="text"
              name="event"
              placeholder="Event *"
              onChange={handleChange}
            />
            {error.event && <Error>{error.event}</Error>}
          </div>
          <div>
            <TextInput
              type="text"
              name="email"
              placeholder="Email *"
              onChange={handleChange}
            />
            {error.email && <Error>{error.email}</Error>}
          </div>
          <div className="textarea-container">
            <TextArea
              placeholder="Description *"
              name="description"
              onChange={handleChange}
            />
            {error.description && <Error>{error.description}</Error>}
          </div>
          <div>
            <Label htmlFor="terms">
              <Checkbox
                id="terms"
                type="checkbox"
                name="terms"
                value={state.terms}
                onChange={handleTerms}
              />
              I agree to the
              <StyledLink to="/terms-and-conditions">
                {" "}
                terms and conditions{" "}
              </StyledLink>{" "}
              and
              <StyledLink to="/privacy-policy"> privacy policy. </StyledLink>
            </Label>
            {error.terms && <Error>{error.terms}</Error>}
          </div>
          <Label htmlFor="marketing">
            <Checkbox
              id="marketing"
              type="checkbox"
              name="marketing"
              value={state.marketing}
              onChange={handleMarketing}
            />
            I want to receive e-mail newletters about new acts.
          </Label>
          <Button
            color={color}
            type="submit"
            disabled={Object.keys(error).length > 0}
          >
            Send
          </Button>
        </Form>
      </Container>
    </Wrapper>
  )
}

export default SlideInForm
