import { useStaticQuery, graphql } from "gatsby"

export const useBlogAssets = () => {
  const images = useStaticQuery(
    graphql`
      query BlogAssets {
        mediaVideo: file(relativePath: { eq: "flipunit__cover__media.mp4" }) {
          publicURL
        }
      }
    `
  )

  return {
    video: images.mediaVideo.publicURL,
  }
}
