import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import { usePosts } from "../hooks/posts"
import Seo from "../components/seo"
import Container from "../components/common/Container"
import { useBlogAssets } from "../hooks/blog"
import PostPreview, { Post, PostExcerpt, PostLink } from "../components/post"

const StyledContainer = styled(Container)`
  margin-bottom: 5.5555556rem;
`
const AltTitleContainer = styled.div`
  text-align: center;
  margin-bottom: 3.125rem;
`
const SecondaryTitle = styled.h1`
  font-family: "Teko", sans-serif;
  color: #fff;
  font-size: 4.125rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 3.125rem;
`
const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`
const MorePostContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5.5555556rem;
`
const Button = styled.button``
const MorePostButton = styled(Button)`
  font-size: 1.77777778em;
  padding: 15px 40px 10px 40px;
`
const Row = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  ${PostLink}:first-child {
    ${Post} {
      justify-content: flex-start;
    }

    ${PostExcerpt} {
      display: flex;
    }
  }

  &.reverse {
    ${PostLink}:first-child {
      ${Post} {
        justify-content: flex-start;
      }

      ${PostExcerpt} {
        display: none;
      }
    }
    & > ${PostLink}:nth-child(2) {
      ${Post} {
        justify-content: flex-start;
      }

      ${PostExcerpt} {
        display: flex;
      }
    }
  }

  @media all and (min-width: 821px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    ${PostLink}:first-child {
      grid-area: 1 / 1 / 3 / 2;

      ${Post} {
        justify-content: flex-end;
      }

      ${PostExcerpt} {
        display: flex;
      }
    }

    &.reverse {
      ${PostLink}:first-child {
        grid-area: 1 / 1 / 2 / 2;

        ${Post} {
          justify-content: flex-start;
        }

        ${PostExcerpt} {
          display: none;
        }
      }
      & > ${PostLink}:nth-child(2) {
        grid-area: 1 / 2 / 3 / 3;

        ${Post} {
          justify-content: flex-end;
        }

        ${PostExcerpt} {
          display: flex;
        }
      }
    }
  }
`
const Video = styled.video`
  height: 300px;
  object-fit: cover;
  width: 100%;
  margin-bottom: 6.25rem;
`

function splitIntoChunk(arr, chunk) {
  let tempArray = []
  for (let i = 0; i < arr.length; i += chunk) {
    tempArray = [...tempArray, arr.slice(i, i + chunk)]
  }
  return tempArray
}

const BlogPage = () => {
  const { video } = useBlogAssets()
  const posts = usePosts()
  const renderPosts = splitIntoChunk(posts, 3)
  return (
    <Layout>
      <Video autoPlay muted loop playsInline preload="metadata">
        <source src={video} type="video/mp4" />
      </Video>
      <StyledContainer>
        <AltTitleContainer>
          <SecondaryTitle className="wow animate_fadeIn">Blog</SecondaryTitle>
        </AltTitleContainer>
        <BlogGrid>
          {renderPosts.map((postArray, idx) => (
            <Row key={idx} className={(idx + 1) % 2 === 0 ? "reverse" : ""}>
              {postArray.map(({ slug, title, cover, excerpt }, idx) => (
                <PostPreview
                  key={idx}
                  className="wow animate__fadeIn"
                  data-wow-delay={`${idx * 0.1}s`}
                  itemScope
                  itemType="http://schema.org/Article"
                  cover={cover.src.gatsbyImageData.images.fallback.src}
                  title={title}
                  excerpt={excerpt}
                  slug={slug}
                />
              ))}
            </Row>
          ))}
        </BlogGrid>
        {posts.length > 10 ? (
          <MorePostContainer>
            <MorePostButton className="wow fadeIn" to="/">
              További Cikkek
            </MorePostButton>
          </MorePostContainer>
        ) : null}
      </StyledContainer>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="BLOG | Behind the scenes of an extreme sport show agency"
    slug="/blog"
    description="Read all about the behind the scenes of extreme sport show agency, entertainment provider company specialized in sport shows for event entertainment."
  />
)

export default BlogPage
