/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components/macro"

import Header from "./header"
import "./layout.css"
import Footer from "./Footer"
import GradientLine from "./gradient-line"
import SlideInForm from "./SlideInForm"
import Up from "./Up"
import CookieConsent from "./CookieConsent"

const Main = styled.main`
  transition: all 0.2s ease-in-out;
  transform: none;

  &.is-open {
    transform: translate(-275px);
  }
`
const Wrapper = styled.div`
  &.is-open {
    overflow: hidden;
    height: 100vh;
  }
`
const Overlay = styled.div`
  opacity: 0;
  background-color: #000;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &.is-menu-open {
    opacity: 0.7;
    width: calc(100% - 275px);
    visibility: visible;
    position: absolute;
    top: 85px;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &.is-slide-open {
    opacity: 0.7;
    width: 100%;
    visibility: visible;
    position: absolute;
    top: 85px;
    right: 0;
    left: 0;
    bottom: 0;
  }
`

const Layout = ({ children, page }) => {
  const [isOpen, setMenu] = useState(false)
  const [isSlideInOpen, setIsSlideInOpen] = useState(false)
  const toggleMenu = () => setMenu(!isOpen)
  const toggleSlideIn = () => setIsSlideInOpen(!isSlideInOpen)
  const closeOverlays = () => {
    setMenu(false)
    setIsSlideInOpen(false)
  }
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Wrapper className={(isOpen || isSlideInOpen) && "is-open"}>
      <CookieConsent />
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        isOpen={isOpen}
        menuHandler={toggleMenu}
        page={page}
      />
      <GradientLine page={page} />
      <Main className={isOpen && "is-open"}>
        {page === "tailor" ? (
          <SlideInForm
            titleBox="Ask for an offer"
            title="Tell use more"
            description="about your event and the type of performance you are looking for!"
            strongPart="We are going to get back to you in 24 hours!"
            color="#b7ff12"
            mailchimpTags={["Tailor made beúszó formon iratkozott fel"]}
            webhookURL="https://flipunitprod-en.netlify.app/.netlify/functions/tailor"
            isOpen={isSlideInOpen}
            toggleIsOpen={toggleSlideIn}
            formName="Tailor made slide"
          />
        ) : page === "airtrack" ? (
          <SlideInForm
            titleBox="Book the show"
            title="Fill the form,"
            description="book the FLIP UNIT Airtrack show and "
            strongPart="we are going to send you an offer within 24 hours!"
            color="#c5c005"
            mailchimpTags={[
              "AirTrack show oldali beúszó formon iratkozott fel",
            ]}
            webhookURL="https://flipunitprod-en.netlify.app/.netlify/functions/airtrack"
            isOpen={isSlideInOpen}
            toggleIsOpen={toggleSlideIn}
            formName="Airtrack slide"
          />
        ) : null}
        {children}
      </Main>
      <GradientLine page={page} />
      <Footer
        isOpen={isOpen}
        color={
          page === "tailor"
            ? "#c4ff45"
            : page === "home"
            ? undefined
            : "#01aced"
        }
      />
      <Overlay
        className={
          isOpen ? "is-menu-open" : isSlideInOpen ? "is-slide-open" : ""
        }
        onClick={closeOverlays}
      />
      <Up
        style={{
          backgroundColor:
            page === "tailor"
              ? "#c4ff45"
              : page === "home"
              ? undefined
              : "#01aced",
          color:
            page === "tailor" ? "#000" : page === "home" ? undefined : "#fff",
        }}
      />
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
