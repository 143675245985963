import { Link } from "gatsby"
import React from "react"
import styled from "styled-components/macro"

export const PostLink = styled(Link)`
  text-decoration: none;
`
export const Post = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 285px;
  height: 100%;
  background-color: transparent;
  position: relative;
  background: ${props => `url(${props.bg}) rgba(0, 0, 0, 0.4)`};
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  transition: all 0.3s ease-in-out;
  padding: 20px;

  @media all and (min-width: 821px) {
    padding: 30px;
    max-width: 590px;
  }
`
export const PostTitle = styled.h3`
  color: #fff;
  font-size: 1.75rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1.25rem;
  line-height: 1;
  word-break: break-word;

  @media all and (min-width: 821px) {
    font-size: 1.75rem;
    margin-bottom: 1.875rem;
  }
`
export const PostExcerpt = styled.p`
  display: block;
  margin-bottom: 0;
  font-size: 1.125rem;

  @media all and (min-width: 821px) {
    display: none;
    font-size: 1.25rem;
  }
`

const PostPreview = ({ cover, title, excerpt, slug, ...rest }) => (
  <PostLink to={slug} {...rest}>
    <Post itemScope itemType="http://schema.org/Article" bg={cover}>
      <PostTitle itemProp="name">{title}</PostTitle>
      <PostExcerpt>{excerpt}</PostExcerpt>
    </Post>
  </PostLink>
)

export default PostPreview
