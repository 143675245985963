import { useStaticQuery, graphql } from "gatsby"

export const usePosts = () => {
  const collection = useStaticQuery(
    graphql`
      query posts {
        posts: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/posts/" } }
          sort: { fields: frontmatter___publishDate, order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                slug
                title
                cover {
                  src: childImageSharp {
                    gatsbyImageData(formats: [JPG], width: 1200)
                  }
                }
                publishDate
                excerpt
              }
            }
          }
        }
      }
    `
  )

  return collection.posts.edges.reduce(
    (acc, post) => [
      ...acc,
      {
        id: post.node.id,
        ...(post.node ? { ...post.node.frontmatter } : {}),
      },
    ],
    []
  )
}
